<template>
	<div class="animated fadeIn">
		<b-card class="card-border" title="Add Dispatch" sub-title="Manages the creation of new dispatches for all companies">
			<br />
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								SOURCE AND DESTINATION
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>SOURCE</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company" label-for="Source Company">
											<v-select name="Source Company" class="style-chooser" label="text"
												:options="sourceCompanyOptions" :reduce="(company) => company.value"
												v-model="selSourceCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Company')" class="help-block">
												{{ errors.first('Source Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location" label-for="Source Storage Location">
											<v-select name="Source Storage Location" class="style-chooser" label="text"
												:options="sourceLocationOptions" :reduce="(loc) => loc.value"
												v-model="selSourceLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Source Storage Location')" class="help-block">
												{{ errors.first('Source Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-home"></i>&nbsp;
										<b>DESTINATION</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Company" label-for="Destination Company">
											<v-select name="Destination Company" class="style-chooser" label="text"
												:options="destinationCompanyOptions"
												:reduce="(company) => company.value" v-model="selDestinationCompany"
												v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Company')" class="help-block">
												{{ errors.first('Destination Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Storage Location" label-for="Destination Storage Location">
											<v-select name="Destination Storage Location" class="style-chooser"
												label="text" :options="destinationLocationOptions"
												:reduce="(loc) => loc.value" v-model="selDestinationLocation"
												v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Destination Storage Location')"
												class="help-block">
												{{ errors.first('Destination Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-2">
									<b-col sm="12">
										<i class="icon-info"></i>&nbsp;
										<b>REMARKS</b>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Remarks" label-for="Remarks" description>
											<b-form-textarea name="Remarks" type="text" v-model="form.notes"
												maxlength="200" v-validate="{
													required: true,
													regex: remarksRegex
												}" :rows="3" placeholder="Remarks" />
											<span v-show="errors.has('Remarks')" class="help-block">
												{{ errors.first('Remarks') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion3 variant="primary"
								class="text-sm-left accordion-title">TRANSPORTATION</b-btn>
						</b-card-header>
						<b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Plate No/CR/OR" label-for="Plate No">
											<v-select class="style-chooser" name="Plate No" label="text"
												:options="allTransportationsOptions"
												:reduce="(selTransportation) => selTransportation.value"
												v-validate="'selectRequired'" v-model="selTransportation" />
											<span v-show="errors.has('Plate No')" class="help-block">
												{{ errors.first('Plate No') }}
											</span>
										</b-form-group>
									</b-col>

									<b-col lg="4" md="6" sm="12" v-if="!(selTransportation.name === ' - Others - ')">
										<b-form-group label="Company" label-for="company">
											<b-form-input name="Company" type="text" v-model="selTransportation.company"
												maxlength="50" readonly />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" v-else>
										<b-form-group label="Company" label-for="company">
											<b-form-input id="company" name="Company" type="text"
												v-model.trim="form.newTransportationToAdd.company" v-validate="{
													required: true,
													regex: nameRegex,
												}" maxlength="100" />
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
										<b-form-group label="Plate No/CR/OR" label-for="plateNumber">
											<b-form-input id="plateNumber" name="Plate Number" type="text"
												v-model.trim="form.newTransportationToAdd.plateNo" v-validate="{
													required: true,
													regex: plateNoRegex,
												}" maxlength="12" placeholder="Plate No/Certification of Registration (CR)/OR" />
											<span v-show="errors.has('Plate Number')" class="help-block">
												{{ errors.first('Plate Number') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Driver" label-for="driver">
											<b-form-input name="Driver" type="text" v-model="form.driver.name"
												maxlength="100" v-validate="{
													required: true,
													regex: fullNameRegex
												}" />
											<span v-show="errors.has('Driver')" class="help-block">{{
												errors.first('Driver')
											}}</span>
										</b-form-group>

										<b-form-group label="Drivers License" label-for="drivers-license"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="drivers-license" placeholder="Choose image"
												ref="dirvers-lincense-file-input" accept="image/png"
												@change="onSelectDriversLicense($event)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Assistant/s" label-for="assistants">
											<b-form-input name="Assistants" type="text" v-model="form.driver.assistants"
												maxlength="100" v-validate="{
													required: false,
													regex: fullNameRegex
												}" />
											<span v-show="errors.has('Assistants')" class="help-block">{{
												errors.first('Assistants')
											}}</span>
										</b-form-group>

										<!-- Image Preview -->
										<div v-if="driversLicense.url">
											<b-card overlay :img-src="driversLicense.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm"
														@click="onRemoveDriversLicense()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploading.uploadStatus === 'uploading'"
												:value="uploading.percentage" :max="uploading.filename"
												variant="success" height="8px" animated />
										</div>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1" v-if="!isSuperAdmin">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion5 variant="primary"
								class="text-sm-left accordion-title">
								ASSETS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<div>
									<b-row>
										<b-col sm="12">
											<i class="icon-direction"></i>
											To add asset entries for dispatch, select items from left
											table then add them to the right table. You can also
											filter the entries by asset type and asset code on the
											Asset List table using the
											<b>filter options</b> below.
										</b-col>
									</b-row>
									<b-row class="my-12">
										<b-col sm="6">
											<AssetDispatchTable :selAssetIds="form.assets" />
										</b-col>
										<b-col sm="6">
											<SelectedAssetDispatchTable @onAddDispatchAssets="addAssets"
												@onDeleteAssets="deleteAssets" />
										</b-col>
									</b-row>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToDispatchMain" class="mr-2">
						Back
					</b-button>
					<b-button v-b-modal.print-dispatch-summary v-b-tooltip.hover.top="'Preview Dispatch Summary'"
						variant="success" @click="printDispatch" :disabled="isLoading" class="mr-2">
						Preview
					</b-button>
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>
		<PrintDispatchSummary :allStorageLocationsObj="allStorageLocationsObj" :allUsersObj="allUsersObj" />
	</div>
</template>

<script>
// Component
import AssetDispatchTable from './AssetDispatchTable';
import SelectedAssetDispatchTable from './SelectedAssetDispatchTable';
import PrintDispatchSummary from '@/views/transactions/dispatch/PrintDispatchSummary';

// Util
import { ArrayUtil } from '@/utils/arrayUtil';
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// DAO & API
import assetDAO from '@/database/assets';
import dispatchApi from '@/api/dispatchApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'add-dispatch',
	components: {
		Loading,
		DateRangePicker,
		AssetDispatchTable,
		SelectedAssetDispatchTable,
		PrintDispatchSummary,
	},
	data() {
		return {
			form: { ...DispatchUtil.getDefaultDispatchObj() },
			showFirstPane: true,
			isPushPull: false,
			driversLicense: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			sourceCompanyOptions: [],
			destinationCompanyOptions: [],
			sourceLocationOptions: [],
			destinationLocationOptions: [],

			selSourceCompany: { ...config.companyDefaultValue },
			selDestinationCompany: { ...config.companyDefaultValue },
			selSourceLocation: { ...config.storageLocationDefaultValue },
			selDestinationLocation: { ...config.storageLocationDefaultValue },
			selTransportation: { ...config.transportationDefaultValue },
			selDispatch: {},

			// Params from Dispatch
			params: {},
			allTransportationsOptions: [],
			allAssetTypesOptions: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},
			allCompanyAssetPoolsObj: {},
			assetsObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
		},

		selSourceCompany: function () {
			this.onChangeSourceCompany();
		},

		selSourceLocation: function () {
			if (this.selSourceLocation) {
				// retrieve the associated assets on the selected company and location
				// and display them on the Asset List Table
				let params = {
					selSourceLocation: this.selSourceLocation,
					allAssetTypesOptions: this.allAssetTypesOptions,
					allDispatchesObj: this.allDispatchesObj,
				};
				EventBus.$emit('onChangeSelSourceLocation', params);
			}
		},

		selDestinationCompany: function () {
			this.onChangeDestinationCompany();
		},

		selTransportation: function (newVal) {
			if (newVal.name === ' - Others - ') {
				this.form.newTransportationToAdd = {
					company: '', // required
					companyId: '',
					createdBy: this.loggedUser.emailAddress,
					dateCreated: DateUtil.getCurrentTimestamp(),
					dateUpdated: null,
					description: null,
					id: null,
					isActive: 'true',
					plateNo: '', // required
					updatedBy: null,
				};
			} else {
				this.form.newTransportationToAdd = {};
			}
		},
	},
	computed: {
		remarksRegex() {
			return config.remarksRegex;
		},
		fullNameRegex() {
			return config.fullNameRegex;
		},
		nameRegex() {
			return config.nameRegex;
		},
		plateNoRegex() {
			return config.plateNoRegex;
		}
	},
	mounted() {
		setTimeout(async () => {
			// Filter Access
			if (this.$store.getters.isViewer) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}
			
			// show loading indicator
			this.isLoading = true;

			// init parameters
			this.params = this.$store.getters.dispatchParams;

			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
			this.allConnectionsObj = this.params.allConnectionsObj;
			this.allUsersObj = this.params.allUsersObj;
			this.allTransportationsObj = this.params.allTransportationsObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;
			this.allCompanyAssetPoolsObj = this.params.allCompanyAssetPoolsObj;
			this.selDispatch = this.$store.getters.currDispatchFromPushPull;

			// reset options
			this.sourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
			this.allTransportationsOptions = DropDownItemsUtil.retrieveTransportations(this.allTransportationsObj);
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			this.onReset();

			// Only reflect pushpull fields when there is value for selDispatch
			if (!_.isEmpty(this.selDispatch)) {
				await this.initPushPull();
			}

			// hide loading indicator
			this.isLoading = false;

		}, config.timeout);
	},
	methods: {
		resetSourceStorageLocationOptions() {
			this.sourceLocationOptions = [];
			this.sourceLocationOptions.push({
				value: { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.selSourceLocation = { ...config.storageLocationDefaultValue };
		},
		resetDestinationCompanyOptions() {
			this.destinationCompanyOptions = [];
			this.destinationCompanyOptions.push({
				value: { ...config.companyDefaultValue },
				text: ' - Please select - ',
			});

			this.selDestinationCompany = { ...config.companyDefaultValue };
		},
		resetDestinationStorageLocationOptions() {
			this.destinationLocationOptions = [];
			this.destinationLocationOptions.push({
				value: { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.selDestinationLocation = { ...config.storageLocationDefaultValue };
		},
		printDispatch() {
			this.form = this.processDispatchForm();
			this.$store.commit('SET_CURR_DISPATCH', this.form);
			EventBus.$emit('onPrintDispatch', this.form);
		},

		onChangeSourceCompany() {
			if (this.selSourceCompany.id === null) {
				// reset all source and destination fields
				this.resetSourceStorageLocationOptions();
				this.resetDestinationCompanyOptions();
				this.resetDestinationStorageLocationOptions();
			} else {
				// retrieve the source storage locations
				this.sourceLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationsByConnection(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selSourceCompany.id,
						this.selSourceCompany.id
					);

				// pre-select the single option value
				if (this.sourceLocationOptions.length === 2) {
					this.selSourceLocation = this.sourceLocationOptions[1].value;
				}

				// update destination company options
				if (this.selSourceCompany && this.selSourceCompany.id !== null) {
					if (this.isSuperAdmin) {
						this.destinationCompanyOptions = DropDownItemsUtil.retrieveConnectedCompanies(this.allConnectionsObj, this.selSourceCompany);
					} else {
						let companyId = this.selSourceCompany.id;
						let parentCompanyId = this.allCompaniesObj[this.selSourceCompany.id].parentCompanyId;

						if (companyId === this.loggedUserCompany.id || parentCompanyId === this.loggedUserCompany.id) {
							this.destinationCompanyOptions = DropDownItemsUtil.retrieveConnectedCompanies(this.allConnectionsObj, this.selSourceCompany);
						} else {
							let companiesObj = _.filter(this.allCompaniesObj, o => {
								return o.id === this.loggedUserCompany.id || o.parentCompanyId === this.loggedUserCompany.id;
							});
							this.destinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(companiesObj);
						}
					}
				}

				this.form.fromInactiveNode = DispatchUtil.isInactiveCompany(this.allCompaniesObj[this.selSourceCompany.id]);

				// update the list of source scanners based on the selected source company
				this.retrieveScanners();
			}
		},
		onChangeDestinationCompany() {
			if (this.selDestinationCompany.id === null) {
				// retrieve the destination storage locations
				this.resetDestinationStorageLocationOptions();
			} else {
				if (this.selSourceCompany.isActive !== 'true') {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationsByCompany(
							this.selDestinationCompany.id,
							this.allStorageLocationsObj
						);
				} else {
					this.destinationLocationOptions =
						DropDownItemsUtil.retrieveStorageLocationsByConnection(
							this.allConnectionsObj,
							this.allStorageLocationsObj,
							this.selSourceCompany.id,
							this.selDestinationCompany.id
						);
				}

				// pre-select the single option value
				if (this.destinationLocationOptions.length == 2) {
					this.selDestinationLocation = this.destinationLocationOptions[1].value;
				}

				this.form.toInactiveNode = DispatchUtil.isInactiveCompany(this.allCompaniesObj[this.selDestinationCompany.id]);

				// update the list of source scanners based on the selected source company
				this.retrieveScanners();
			}
		},

		retrieveScanners() {
			this.form.sourceScanners = [];

			let selCompanyId = DispatchUtil.getCompanyIdForUserOptions(
				this.selSourceCompany,
				this.selDestinationCompany
			);

			if (selCompanyId) {
				_.forEach(this.allUsersObj, (user) => {
					if (user.companyId === selCompanyId) {
						this.addScannerInBackground(user);
					}
				});
			}
		},
		addScannerInBackground(user) {
			if (!_.find(this.form.sourceScanners, { id: user.id })) {
				let scanner = {
					id: user.id,
					name: user.firstName + ' ' + user.lastName,
					type: user.type,
					scannedAssets: [],
				};
				this.form.sourceScanners.push(scanner);
			}
		},

		plateNoExists(transportation) {
			let currPlateNo = '';

			let currDispatch = this.$store.getters.currDispatch;
			if (
				!_.isEmpty(currDispatch) &&
				currDispatch.transportation !== null &&
				currDispatch.transportation.plateNo !== null
			) {
				currPlateNo = currDispatch.transportation.plateNo;
			}

			const plateNos = _.map(this.allTransportationsObj, (o) => {
				let plateNo = ""
				if (o.plateNo && !_.isEmpty(o.plateNo)) {
					plateNo = o.plateNo.toUpperCase();
				}
				return plateNo;
			});

			return (
				plateNos.includes(transportation.plateNo.toUpperCase()) &&
				currPlateNo != transportation.plateNo
			);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			// source and destination must not be the same
			if (this.selSourceCompany.id === this.selDestinationCompany.id && this.selSourceLocation.id === this.selDestinationLocation.id) {
				this.$toaster.warning('Source and Destination should not be the same');
				this.isLoading = false;
				return;
			}

			// for new transportation created on-the-fly
			if (this.selTransportation.name === ' - Others - ') {
				if (!DispatchUtil.isValidTransportation(this.form.newTransportationToAdd)) {
					this.$toaster.warning('Company or Plate Number is required.');
					this.isLoading = false;
					return;
				} else if (this.plateNoExists(this.form.transportation)) {
					this.$toaster.warning('Plate number already exists.');
					this.isLoading = false;
					return;
				}
			}

			if (DispatchUtil.exceedMaximumAssetTypes(this.assetsObj)) {
				this.$toaster.warning('You have exceeded the allowed number of asset types (30) per dispatch.');
				this.isLoading = false;
				return;
			}

			this.form = this.processDispatchForm();
			await this.handleSubmit();
		},

		processDispatchForm() {
			let currTimestamp = DateUtil.getCurrentTimestamp();

			// Update Dispatch Fields
			this.form.dispatchId = 'DS' + currTimestamp;
			this.form.dateCreated = currTimestamp;
			this.form.createdBy = this.loggedUser.id;
			this.form.dateUpdated = currTimestamp;
			this.form.updatedBy = this.loggedUser.id;

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);

			this.updateDriver();
			this.updateSourceSelection();
			this.updateDestinationSelection();
			this.updateTransportation();

			return this.form;
		},
		updateDriver() {
			this.form.driver.name = ValidationUtil.removeExcessWhiteSpace(
				this.form.driver.name
			);
			this.form.driver.assistants = ValidationUtil.removeExcessWhiteSpace(
				this.form.driver.assistants
			);
			this.form.driver.licenseUrl = this.driversLicense.url;
		},
		updateSourceSelection() {
			this.form.source = {
				company: this.selSourceCompany.name !== ' - Please select - ' ? this.selSourceCompany.name : '-',
				companyId: this.selSourceCompany.id,
				storageLocation: this.selSourceLocation.name !== ' - Please select - ' ? this.selSourceLocation.name : '',
				storageLocationId: this.selSourceLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selSourceLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(this.selSourceLocation.geoaddress),
				longitude: LocationUtil.getLongitude(this.selSourceLocation.geoaddress),
			};
		},
		updateDestinationSelection() {
			this.form.destination = {
				company: this.selDestinationCompany.name !== ' - Please select - ' ? this.selDestinationCompany.name : '-',
				companyId: this.selDestinationCompany.id,
				storageLocation: this.selDestinationLocation.name !== ' - Please select - ' ? this.selDestinationLocation.name : '-',
				storageLocationId: this.selDestinationLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selDestinationLocation.geoaddress
				),
				latitude: LocationUtil.getLatitude(
					this.selDestinationLocation.geoaddress
				),
				longitude: LocationUtil.getLongitude(
					this.selDestinationLocation.geoaddress
				),
			};
		},
		updateTransportation() {
			if (!_.isEmpty(this.form.newTransportationToAdd)) {
				this.form.transportation.plateNo = this.form.newTransportationToAdd.plateNo;
				this.form.transportation.company = this.form.newTransportationToAdd.company;
				this.form.transportation.companyId = this.form.newTransportationToAdd.companyId;
			} else {
				this.form.transportation.plateNo = this.selTransportation.name;
				this.form.transportation.company = this.selTransportation.company;
				this.form.transportation.companyId = this.selTransportation.companyId;
			}
		},

		async uploadDriversLicensePhoto(dispatch) {
			let image = this.driversLicense;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image, dispatch);
				if (uploadResult.name && uploadResult.url) {
					dispatch.driver.licenseUrl = uploadResult.url;
					const updateResult = await dispatchApi.saveDispatch(
						dispatch,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress('success');
					}
				}
			}
		},
		async firebaseUploadImage(image, dispatch) {
			let filename = `${dispatch.dispatchId}_${dispatch.driver.name}.png`;

			this.onUploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${image.fbStoragePath}/${filename}`);
				let task = storageRef.put(image.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		onUploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},
		onResetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},
		onRemoveDriversLicense() {
			this.driversLicense = {};
			this.$refs['dirvers-lincense-file-input'].reset();
		},
		onSelectDriversLicense(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error('Invalid File Type: Please import a valid license photo in PNG or JPEG format.');
				this.driversLicense = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning("Drivers lincense photo's width and height shouldn't be greater than 1000 pixels");
				} else {
					vm.driversLicense = {
						url: url,
						file: file,
						fbStoragePath: 'images/driversLicenses',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		async handleSubmit() {
			let dispatchId = this.form.dispatchId;

			try {
				let { data } = await dispatchApi.saveDispatch(this.form, this.loggedUser.id);

				if (data.isSuccess) {
					// upload driverse license photo
					if (!_.isEmpty(this.driversLicense)) {
						await this.uploadDriversLicensePhoto(data.dispatch);
					}

					this.$toaster.success(`New Dispatch "${dispatchId}" was created successfully.`);
					EventBus.$emit('onCloseSaveDispatch', data.dispatch);
					this.addNewTransportationToStore(data.dispatch);

					// reset the state and revert to dispatch page
					this.returnToDispatchMain();
				} else {
					this.$toaster.error(data.message);
				}
			} catch (_error) {
				this.$toaster.error(`Error creating dispatch ${dispatchId}. Please try again.`);
			}

			// reset
			this.assetsToDelete = [];
			// hide loading indicator
			this.isLoading = false;
		},
		addNewTransportationToStore(dispatch) {
			if (dispatch.newTransportationToAdd && !_.isEmpty(dispatch.newTransportationToAdd)) {
				let newTransportations = {};
				newTransportations[dispatch.newTransportationToAdd.id] = dispatch.newTransportationToAdd;
				this.$store.dispatch('updateAllTransportations', newTransportations);
			}

			if (dispatch.newCompanyToAdd && !_.isEmpty(dispatch.newCompanyToAdd)) {
				let newCompanies = {};
				newCompanies[dispatch.newCompanyToAdd.id] = dispatch.newCompanyToAdd;
				this.$store.dispatch('updateAllCompanies', newCompanies);
			}
		},
		returnToDispatchMain() {
			this.params.fromAddDispatch = true;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/dispatch' });
			} else {
				this.$router.push({ path: '/admin/admin-dispatch' });
			}
		},

		addAssets(selAssetsArr) {
			let newAssetsObj = _.keyBy(selAssetsArr, 'assetCode');
			let assetsObj = { ...this.assetsOObj, ...newAssetsObj };

			if (DispatchUtil.exceedMaximumAssetTypes(assetsObj)) {
				this.$toaster.warning('You have exceeded the allowed number of asset types (30) per dispatch.');
				return;
			}

			// proceed with adding new asset(s)
			_.forEach(selAssetsArr, (assetObj) => {
				let assetCode = assetObj.assetCode ? assetObj.assetCode : '';

				if (!this.form.assets.includes(assetCode)) {
					// add to dispatch assets and inputAssetLog
					this.assetsObj[assetCode] = assetObj;
					this.form.assets.push(assetCode);
					this.form.inputAssetLog['dispatch'].manual.push(assetCode);

					// add assets for all source scanners
					this.form.sourceScanners.forEach((scanner) => {
						if (scanner.id === this.loggedUser.id) {
							scanner.scannedAssets.push(assetCode);
						}
					});
				}
			});
		},
		deleteAssets(selAssetCodesArr) {
			_.forEach(selAssetCodesArr, (assetCode) => {
				if (this.form.assets.includes(assetCode)) {

					// delete from dispatch assets and inputAssetLog
					delete this.assetsObj[assetCode];
					this.form.assets = ArrayUtil.removeItem(this.form.assets, assetCode);
					this.form.inputAssetLog['dispatch'].manual = ArrayUtil.removeItem(
						this.form.inputAssetLog['dispatch'].manual,
						assetCode
					);

					// delete assets from all source scanners
					this.form.sourceScanners.forEach((scanner) => {
						scanner.scannedAssets = ArrayUtil.removeItem(
							scanner.scannedAssets,
							assetCode
						);
					});
				}
			});
		},

		onReset() {
			/* Reset our form values */

			// primary details
			this.form.dispatchId = '';
			this.form.source = {};
			this.form.destination = {};
			this.form.status = 'Draft';
			this.form.notes = '';

			// scanners
			this.form.sourceScanners = [];
			this.form.destinationScanners = [];

			// transportation
			this.form.transportation = { ...config.dispatchTransportDefaultValue };

			// driver
			this.form.driver = { ...config.dispatchDriverDefaultValue }
			this.driversLicense = {};

			// assets
			this.form.assets = [];
			this.form.damagedAssets = [];
			this.form.inputAssetLog = { ...config.dispatchInputAssetLogDefaultValue };

			// others
			this.form.fromInactiveNode = 'false';
			this.form.toInactiveNode = 'false';

			this.onResetDropDownValues();
			this.onResetUploadingState();

			// init assets object
			this.assetsObj = {};
			EventBus.$emit('resetSelectedAssetList');

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		onResetDropDownValues() {
			if (!this.isSuperAdmin) {
				// select the source company by default
				this.selSourceCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				this.onChangeSourceCompany();
			} else {
				this.selSourceCompany = { ...config.companyDefaultValue };
			}

			this.selDestinationCompany = { ...config.companyDefaultValue };
			this.selSourceLocation = { ...config.storageLocationDefaultValue }
			this.selDestinationLocation = { ...config.storageLocationDefaultValue };
			this.selTransportation = { ...config.transportationDefaultValue };
		},

		async initPushPull() {
			// Mark as pushpull
			this.isPushPull = true;
			let currTimestamp = DateUtil.getCurrentTimestamp();

			let dispatch = { ...this.selDispatch };

			// primary details
			this.form.dispatchId = 'DS' + currTimestamp;
			this.form.source = dispatch.destination;
			this.form.destination = dispatch.source;
			this.form.status = 'Receiving';
			this.form.notes = 'Created Push Pull from ' + this.selDispatch.dispatchId;

			// transportation
			this.form.transportation = dispatch.transportation;
			this.form.driver = dispatch.driver;
			this.driversLicense = DispatchUtil.getDriverLicenseObj(this.form.driver);

			// assets and scanners
			this.form.assets = dispatch.actualAssets;
			this.form.actualAssets = dispatch.actualAssets;
			this.form.addedExpectedAssets = dispatch.actualAssets;
			this.form.damagedAssets = dispatch.damagedAssets;
			this.form.sourceScanners = [];
			this.form.destinationScanners = [];

			// timestamps
			this.form.dateCreated = currTimestamp;
			this.form.createdBy = this.loggedUser.id;
			this.form.dateDeployed = currTimestamp;
			this.form.deployedBy = this.loggedUser.id;

			// Others
			this.form.creationSource = { ...config.dispatchCreationSource.PUSHPULL };

			let sourceCompanyId = dispatch.source.companyId;
			let sourceCompany = this.allCompaniesObj[sourceCompanyId];
			this.form.fromInactiveNode = DispatchUtil.isInactiveCompany(sourceCompany);

			let destinationCompanyId = dispatch.destination.companyId;
			let destinationCompany = this.allCompaniesObj[destinationCompanyId];
			this.form.toInactiveNode = DispatchUtil.isInactiveCompany(destinationCompany);

			// Input Asset Log: make the assets auto-scanned both in dispatch and receipt
			this.form.inputAssetLog = { ...config.dispatchInputAssetLogDefaultValue };
			this.form.inputAssetLog['dispatch'].auto = this.form.assets;
			this.form.inputAssetLog['receipt'].auto = this.form.assets;

			// Update Asset List Tables
			this.onResetDropDownForPushPull(dispatch);

			let assetsObjResult = await assetDAO.getAssetsByCode(dispatch.actualAssets);
			this.assetsObj = assetsObjResult[0];
			EventBus.$emit('initSelectedAssets', {
				companyId: this.selSourceCompany.id,
				storageLocationId: this.selSourceLocation.id,
				selAssetIds: dispatch.assets,
				assetsObj: this.assetsObj
			});

			// Set the current dispatch in the store during push pull
			this.$store.dispatch('setCurrentDispatch', this.form);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		onResetDropDownForPushPull(dispatch) {
			// SOURCE
			let sourceCompany = this.allCompaniesObj[dispatch.destination.companyId];
			this.selSourceCompany = DropDownItemsUtil.getCompanyItem(sourceCompany);
			this.onChangeSourceCompany();

			let sourceLocation = this.allStorageLocationsObj[dispatch.destination.storageLocationId];
			this.selSourceLocation = DropDownItemsUtil.getStorageLocationItem(sourceLocation);

			// DESTINATION
			let destinationCompany = this.allCompaniesObj[dispatch.source.companyId];
			this.selDestinationCompany = DropDownItemsUtil.getCompanyItem(destinationCompany);
			this.onChangeDestinationCompany();

			let destinationLocation = this.allStorageLocationsObj[dispatch.source.storageLocationId];
			this.selDestinationLocation = DropDownItemsUtil.getStorageLocationItem(destinationLocation);

			const index = this.allTransportationsOptions.findIndex(
				(item) => item.value.name === dispatch.transportation.plateNo
			);

			this.selTransportation = this.allTransportationsOptions[index].value;
		},
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}
</style>
